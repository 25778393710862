import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/404',
    component: () => import('../views/404.vue'),
    hidden: true
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/article/:id',
    name: 'Article',
    component: () => import('../views/ArticleDetail.vue')
  },
  {
    path: '/member',
    name: 'Member',
    component: () => import('../views/Member.vue')
  },
  {
    path: '/member/:category',
    name: 'MemberArticle',
    component: () => import('../views/MemberArticle.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/category/:id',
    name: 'Category',
    component: () => import('../views/Category.vue')
  },
  {
    path: '/meeting',
    name: 'Meeting',
    component: () => import('../views/Meeting.vue')
  },
  {
    path: '/meeting/:uuid',
    name: 'MeetingDetail',
    component: () => import('../views/MeetingDetail.vue')
  },
  { path: '*', redirect: '/404' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
