<template>
  <div class="nav">
    <div class="nav-wrap">
      <el-row>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="4">
          <div class="logo">
            <img alt="LOGO" :src="logo">
          </div>
        </el-col>
        <el-col class="hidden-xs-only hidden-sm-only" :sm="16" :md="16" :lg="16" :xl="20">
          <div class="profile">
            <div class="item" v-if="module<999">
              <el-input
                placeholder="请输入关键词"
                suffix-icon="el-icon-search"
                @keyup.enter.native="handleSearch"
                v-model="localKeyword">
              </el-input>
            </div>
            <div class="item">
              <div class="logined" v-if="token">
                <div class="hi">
                  <el-popover
                    v-model="changePass"
                    placement="bottom"
                    title="修改密码"
                    width="300"
                    trigger="hover">
                    <el-form ref="form" :model="form" :rules="rules" label-width="120px" class="addnew-form">
                      <el-form-item label="当前密码" :required="true" prop="oldpass">
                        <el-input v-model="form.oldpass" :clearable="true" type="password" maxlength="15" placeholder="请输入当前密码" />
                      </el-form-item>
                      <el-form-item label="新密码" :required="true" prop="password">
                        <el-input v-model="form.password" :clearable="true" type="password" maxlength="15" placeholder="请输入新密码" />
                      </el-form-item>
                      <el-form-item label="确认新密码" :required="true" prop="repassword">
                        <el-input v-model="form.repassword" :clearable="true" type="password" maxlength="15" placeholder="请确认新密码" />
                      </el-form-item>
                      <el-form-item>
                        <el-button size="mini" type="primary" @click="onSubmit">确认修改</el-button>
                      </el-form-item>
                    </el-form>
                    <div class="avatar" slot="reference">
                      <img :alt="name" :src="avatar?avatar:defaultAvatar">
                      Hi, {{ name }}
                    </div>
                  </el-popover>
                </div> | <div class="modify" @click="modify">修改资料</div> | <div class="logout" @click="logout">退出登录</div>
              </div>
              <div v-else>
                <span @click="handleRegister">注册</span> | <span @click="handleLogin">登录</span>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="mobile-menu">
        <i class="el-icon-search search" @click="handleSearch" v-if="module<999"></i>
        <el-popover
          placement="bottom"
          trigger="click">
          <ul class="mini-menu">
            <li v-for="(item, index) in list" :key="index" :class="parseInt(item.id)===module?'active':''" @click="gotoPath(item)">{{ item.name }}</li>
            <el-divider></el-divider>
            <li v-if="token">
              <div class="profile">
                <div class="avatar">
                  <img :alt="name" :src="avatar?avatar:defaultAvatar">
                  {{ name }}
                </div>
              </div>
            </li>
            <li v-if="token" @click="logout">退出登录</li>
            <li v-else @click="handleLogin">点此登录</li>
          </ul>
          <i class="el-icon-menu" slot="reference"></i>
        </el-popover>
      </div>
    </div>
    <div class="menu hidden-sm-only hidden-xs-only">
      <el-row>
        <el-col :md="24">
          <ul>
            <li v-for="(item, index) in list" :key="index" @click="gotoPath(item)" :class="module===item.id?'active':''">{{ item.name }}</li>
          </ul>
        </el-col>
      </el-row>
    </div>
    <el-dialog title="修改资料" :visible.sync="modifyProfile">
      <el-form :model="registerForm" ref="registerForm" :rules="registerRules" label-width="100px">
          <el-form-item label="单位全称" prop="username">
            <el-input v-model="name" minlength="2" maxlength="15" disabled placeholder="请输入单位全称" prefix-icon="el-icon-user-solid"></el-input>
          </el-form-item>
          <el-form-item label="法人姓名" prop="name">
            <el-input v-model="registerForm.name" minlength="2" maxlength="15" placeholder="请输入法人姓名" prefix-icon="el-icon-user-solid"></el-input>
          </el-form-item>
          <el-form-item label="法人证件号" prop="idcard">
            <el-input v-model="registerForm.idcard" minlength="2" maxlength="15" placeholder="请输入法人证件号" prefix-icon="el-icon-bank-card"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="registerForm.phone" minlength="11" maxlength="11" placeholder="请输入联系电话" prefix-icon="el-icon-phone-outline"></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="contact">
            <el-input v-model="registerForm.contact" minlength="11" maxlength="11" placeholder="请输入联系人" prefix-icon="el-icon-user"></el-input>
          </el-form-item>
          <el-form-item label="单位地址" prop="address">
            <el-input v-model="registerForm.address" minlength="11" maxlength="11" placeholder="请输入单位地址" prefix-icon="el-icon-office-building"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button :loading="registerLoading" type="danger" class="btn" @click="handleSave">保存</el-button>
          </el-form-item>
        </el-form>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import logo from '@/assets/logo.png'
import localAvatar from '@/assets/avatar.jpeg'
import { mapGetters } from 'vuex'
export default {
  name: 'CommonNav',
  props: {
    module: {
      type: Number,
      default: -1
    },
    keyword: {
      type: String,
      default: ''
    }
  },
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    var validatePhone = (rule, value, callback) => {
      if (value.length !== 11) {
        callback(new Error('请输入正确的手机号'));
      } else if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback();
      }
    }
    return {
      logo: logo,
      defaultAvatar: localAvatar,
      localKeyword: this.keyword,
      list: [
        {
          id: 0,
          name: '首页',
          url: '/'
        },
        {
          id: 1,
          name: '工作动态',
          url: '/category/1'
        },
        {
          id: 2,
          name: '会员专区',
          url: '/member'
        },
        {
          id: 3,
          name: '会议报名',
          url: '/meeting'
        },
        {
          id: 99,
          name: '联系我们',
          url: '/contact'
        }
      ],
      changePass: false,
      modifyProfile: false,
      form: {
        oldpass: '',
        password: '',
        repassword: ''
      },
      registerLoading: false,
      registerForm: {
        name: '',
        idcard: '',
        contact: '',
        phone: '',
        address: ''
      },
      rules: {
        oldpass: [
          { required: true, message: '请输入原密码', trigger: 'blur' },
          { min: 6, max: 15, message: '密码长度在 6 到 15 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, max: 15, message: '密码长度在 6 到 15 个字符', trigger: 'blur' }
        ],
        repassword: [
          { validator: validatePass, trigger: 'blur' }
        ]
      },
      registerRules: {
        name: [
          { required: true, message: '请输入法人姓名', trigger: 'blur' },
          { min: 2, max: 10, message: '姓名长度应在2到10个字符之间', trigger: 'blur' }
        ],
        idcard: [
          { required: true, message: '请输入法人证件号', trigger: 'blur' },
          { min: 18, max: 18, message: '证件号长度为18位', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入联系手机', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        contact: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
          { min: 2, max: 10, message: '姓名长度应在2到10个字符之间', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入单位地址', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters([
      'name',
      'avatar',
      'token',
      'profile'
    ])
  },
  methods: {
    gotoPath (item) {
      if (this.$route.path !== item.url) {
       this.$router.push({ path: item.url })
      }
    },
    logout () {
      this.$store.dispatch('user/logout').then(res => {
        this.$message.success('退出登录成功')
        this.$router.replace({ path: '/' })
      })
    },
    modify () {
      this.registerForm.name = this.profile.name
      this.registerForm.idcard = this.profile.idcard
      this.registerForm.contact = this.profile.contact
      this.registerForm.phone = this.profile.phone
      this.registerForm.address = this.profile.address
      this.modifyProfile = true
    },
    onSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$http.post('/user/modpass', this.form).then(res => {
            if (res) {
              this.$refs.form.resetFields()
              this.changePass = false
              this.$message({
                type: 'success',
                message: '密码重置成功，请重新登录'
              })
              this.$store.dispatch('user/logout').then(res => {
                this.$router.replace({ path: '/login' })
              })
            } else {
              this.$message({
                type: 'error',
                message: '密码重置失败'
              })
            }
          })
        } else {
          return false
        }
      })
    },
    handleLogin() {
      this.$router.push({ path: '/login' })
    },
    handleRegister() {
      this.$router.push({ path: '/login?register=true' })
    },
    handleSearch() {
      this.$router.push({ path: `/search?keyword=${this.localKeyword}` })
    },
    handleSave() {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          this.registerLoading = true
          this.$store.dispatch('user/modify', this.registerForm)
            .then(() => {
              this.$message.success('个人资料修改成功')
              this.modifyProfile = false
              this.registerLoading = false
            }).catch(() => {
              this.registerLoading = false
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style>
.el-input--suffix .el-input__inner, .el-input--prefix .el-input__inner {
  border-radius: 30px;
}
</style>
<style scoped lang="scss">
@media (max-width: 960px) {
  .nav {
    .nav-wrap {
      height: 80px !important;
      line-height: 80px !important;
      .mobile-menu {
        display: block !important;
        position: absolute;
        right: 15px;
        font-size: 30px;
        top: 4px;
        .search {
          margin-right: 10px;
        }
      }
      .logo {
        img {
          width: 150px !important;
        }
      }
    }
  }
}
.mini-menu {
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    padding: 8px 0;
    text-align: center;
    &.active {
      font-weight: 600;
      color: rgba(223, 48, 32, 1);
    }
    .profile {
      display: flex;
      font-size: 14px;
      div {
        justify-content: center;
        align-content: center;
      }
      .avatar {
        flex-grow: 1;
        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin:0 3px -10px 0;
        }
      }
    }
  }
}
.nav {
  background-color: rgba(237, 241, 247, 1);
  .menu {
    background-color: rgba(223, 48, 32, 1);
    ul {
      list-style: none;
      margin: 0 auto;
      padding: 0;
      font-size: 14px;
      font-weight: 500;
      max-width: 1400px;
      li {
        padding: 0 15px;
        line-height: 55px;
        float: left;
        color: #fff;
        cursor: pointer;
        &.active {
          font-weight: 700;
          background-color: rgba(241, 90, 35, 0.998);
        }
        &:hover {
          background-color: rgba(241, 90, 35, 0.998);
        }
      }
      &:after {
        content: ' ';
        display: block;
        clear: both;
      }
    }
  }
  .nav-wrap {
    height: 128px;
    line-height: 128px;
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    color: #333;
    .mobile-menu {
      display: none;
    }
    .logo {
      text-align: left;
      padding: 0 10px;
      display: table-cell;
      img {
        vertical-align: middle;
        width: 300px;
      }
    }
    .profile {
      display: flex;
      // height: 80px;
      font-size: 14px;
      justify-content: right;
      .item {
        &:first-child {
          margin-right: 30px;
        }
        margin-right: 10px;
        .logined {
          display: flex;
          cursor: pointer;
          .hi {
            color: rgba(223, 48, 32, 1);
            font-weight: 600;
            margin-right: 10px;
          }
          .logout {
            margin-left: 10px;
          }
          .modify {
            margin: 0 10px;
          }
          .avatar {
            a {
              color: #fff;
              text-decoration: none;
            }
            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              margin:0 3px -10px 0;
            }
          }
        }
      }
    }
  }
}
</style>
