<template>
  <div>
    <common-nav :module="0" />
    <div class="content-wrap">
      <div class="block no-shadow">
        <el-row :gutter="20">
          <el-col :xs="24" :md="12">
            <div class="carousel" v-if="topping.length > 0">
              <el-carousel :height="screenWidth<=1024?'220px':'440px'" :loop="true" arrow="always">
                <el-carousel-item v-for="(item, index) in topping" :key="index">
                  <img :src="item.image" :alt="item.title" @click="handleClick" :data-id="item.id">
                </el-carousel-item>
              </el-carousel>
            </div>
          </el-col>
          <el-col :xs="24" :md="12">
            <div class="tabs">
              <el-tabs v-model="activeName">
                <el-tab-pane label="工作动态" name="first">
                  <article-list :category="1" :tab="true"  />
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- <div class="block no-shadow">
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="card-wrap meeting">
              <div class="header">
                <span><router-link :to="{path: '/meeting'}" class="link" target="_blank">更多..</router-link></span>会议报名
              </div>
              <div class="list-wrap">
                <el-empty :image-size="100" v-if="meetings.length===0" description="尚未发布会议"></el-empty>
                <ul v-else>
                  <li v-for="(item,index) in meetings" :key="index" @click="showMeeting(item.uuid)" :class="item.status_text==='已结束'?'gray':''">
                    <el-row :gutter="20">
                      <el-col :xs="14" :sm="16" :md="12"><div class="title">{{ item.name }}</div></el-col>
                      <el-col class="hidden-sm-and-down" :md="3">{{ item.status_text }}</el-col>
                      <el-col class="hidden-sm-and-down" :md="3">{{ item.signup_count }}人次</el-col>
                      <el-col :xs="10" :sm="8" :md="6"><div class="datetime">{{ item.beginDate | dateFormat('yyyy-MM-dd') }} — {{ item.endDate | dateFormat('yyyy-MM-dd') }}</div></el-col>
                    </el-row>
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
        </el-row>
      </div> -->
    </div>
    <common-footer />
  </div>
</template>

<script>
/* eslint-disable */
import CommonNav from '@/components/CommonNav.vue'
import CommonFooter from '../components/CommonFooter.vue'
import ArticleList from './components/ArticleList.vue'
export default {
  name: 'HomeView',
  components: { CommonNav, CommonFooter, ArticleList },
  data () {
    return {
      activeName: 'first',
      topping: [],
      meetings: [],
      screenWidth: 0
    }
  },
  created () {
    this.fetchTopping()
    // this.fetchMeetings()
  },
  mounted() {
    this.screenWidth = window.innerWidth
    this.monitorScreenSize()
  },
  methods: {
    monitorScreenSize() {
      window.addEventListener('resize', this.handleResize)
    },
    handleResize() {
      console.log(window.innerWidth, 'width')
      this.screenWidth = window.innerWidth;
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize)
    },
    fetchTopping() {
      this.$http.get('/web', { params: { module: 'topping', categoryId: 0 } }).then(res => {
        this.topping = res
      })
    },
    handleClick(e) {
      this.$router.push({ path: `/article/${e.target.dataset.id}`, target: '_blank' })
    },
    fetchMeetings() {
      this.$http.get('/web', { params: { module: 'meeting', categoryId: 0 } }).then(res => {
        this.meetings = res
      })
    },
    showMeeting(uuid) {
      this.$router.push({ path: `/meeting/${uuid}` })
    }
  }
}
</script>
<style>
.el-tabs__item {
  font-size: 26px;
}
.el-tabs__nav {
  padding-bottom: 15px;
}
.el-tabs__item.is-active {
  color: rgba(223, 48, 32, 1);
}
.el-tabs__active-bar {
  background-color: rgba(223, 48, 32, 1);
}
@media (max-width: 960px) {
  .el-tabs__item {
    font-size: 18px;
  }
}
</style>
