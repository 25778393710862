<template>
  <div>
    <div v-if="tab">
      <el-empty :image-size="100" v-if="datas.length===0"></el-empty>
      <ul v-else>
        <li v-for="(item,index) in datas" :key="index">
          <router-link :to="{path: '/article/'+item.id}" class="link" target="_blank">{{item.title}}</router-link>
        </li>
      </ul>
    </div>
    <div class="card-wrap" v-else>
      <div class="header">
        <span v-if="more"><router-link :to="{path: '/member/'+category}" class="link" target="_blank">更多..</router-link></span>{{ label }}
      </div>
      <div class="list-wrap">
        <el-empty :image-size="100" v-if="datas.length===0"></el-empty>
        <ul v-else>
          <li v-for="(item,index) in datas" :key="index">
            <span v-if="type==='download'" @click="handleDownload" :data-id="item.id">下载</span>
            <span v-else>{{ item.createTime | dateFormat("yyyy.MM.dd") }}</span>
            <router-link :to="{path: '/article/'+item.id}" class="link" target="_blank">{{item.title}}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  name: 'ArticleListComponent',
  props: {
    category: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'article'
    },
    tab: {
      type: Boolean,
      default: false
    },
    more: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      datas: []
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      this.$http.get('/web', { params: { module: 'category', categoryId: this.category } }).then(res => {
        this.datas = res
      })
    },
    handleDownload(e) {
      e.preventDefault()
      this.$http.get('/web/download', { params: { id: e.target.dataset.id } }).then(res => {
        window.open(res)
      }).catch(e => console.log)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>